
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { CarBaseInfo } from '@/types/carManage'
import { phone } from '@/utils/validate'

@Component({
  name: 'AddBase'
})
export default class extends Vue {
  private info: CarBaseInfo={
    // 基础信息
    projectId: '',
    vehicleType: '',
    vehicleName: '',
    brand: '',
    model: '',
    engineNumber: '',
    driver: '',
    driverMobile: '',
    driverLicenseNum: '',
    insuranceStartTime: '',
    insuranceEndTime: ''
  }

  private projectList = []
  private carTypeList = []
  private dateArr: string[]= []
  private submitShow = false

  private rules= {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: 'change' }
    ],
    vehicleName: [
      { required: true, message: '请输入车牌号', trigger: 'blur' }
    ],
    vehicleType: [
      { required: true, message: '请选择车辆类型', trigger: 'blur' }
    ],
    driverMobile: [
      { validator: phone }
    ]
  }

  get id () {
    return this.$route.params.id as string || ''
  }

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  created () {
    this.id && this.getDetail()
    this.getCarTypeList()
  }

  getDetail () {
    this.$axios.get(this.$apis.carManage.selectYhVehicleByVehicleId, { vehicleId: this.id }).then(res => {
      if (res) {
        this.info = res
        this.dateArr = [res.insuranceStartTime, res.insuranceEndTime]
      }
    })
  }

  // 车辆类型
  getCarTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'vehicleType' }).then(res => {
      this.carTypeList = res.vehicleType || []
    })
  }

  dateChange (date: string[]) {
    if (date) {
      this.info.insuranceStartTime = date[0]
      this.info.insuranceEndTime = date[1]
    } else {
      this.info.insuranceStartTime = ''
      this.info.insuranceEndTime = ''
    }
  }

  saveForm () {
    (this.$refs.form as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        delete this.info.vehicleTypeName
        const urls = this.id ? this.$apis.carManage.updateYhVehicle : this.$apis.carManage.insertYhVehicle
        this.$axios.post(urls, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.back()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
